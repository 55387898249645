import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { updateAuth } from "../store/Reducers/authReducer"; // Adjust the import as needed
import { useReduxStore } from "../hooks/useReduxStore"; // Adjust the import as needed
import * as PaymentService from "../services/paymentService"; // Adjust the import as needed

const CheckoutForm = ({ amount = 10, id, handelModalType }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { dispatch, getState } = useReduxStore();
  const { userData } = getState("AUTH");
  const { email, name } = userData || {};
  const [errorMessage, setErrorMessage] = useState("null");
  const [isEmpty, setIsEmpty] = useState(true);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [recaptchaError, setRecaptchaError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpMessage, setOtpMessage] = useState("");
  const [resendVisible, setResendVisible] = useState(false);
  const [resendVisible1, setResendVisible1] = useState(false);

  const onChangeCard = (obj) => {
    setErrorMessage(obj?.error?.message);
    setIsEmpty(obj?.empty);
  };

  

  const handleRecaptcha = (token) => {
    setRecaptchaToken(token);
    setRecaptchaError(null);
  };

  const isFormValid = () => {
    return !isEmpty && !errorMessage && recaptchaToken;
  };

  const CloseModal = () => {
    if (errorMessage === "OTP verified successfully") {
      setIsModalOpen(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!recaptchaToken) {
      setRecaptchaError("Please complete the CAPTCHA");
      return;
    }
    try {
      if (elements == null) return;

      let elementError = await elements.submit();
      const { error: submitError } = elementError;
      if (submitError) return;

      handelModalType("loading");
      dispatch(updateAuth({ loading: true }));

      let { ok, data } = await PaymentService.createIntent({
        amount,
        name,
        email,
        recaptchaToken,
      });
      const { intent, amount: intentAmount } = data || {};

      if (ok && intent) {
        const { error } = await stripe.confirmCardPayment(intent, {
          payment_method: {
            card: elements.getElement(CardElement),
          },
        });

        if (error) {
          handelModalType("failed");
          setErrorMessage(error.message);
        } else {
          let { ok } = await PaymentService.paymentSuccess({
            product_id: id,
            payment_intent: intent,
            intent_amount: intentAmount,
          });
          if (ok) {
            handelModalType("success");
          } else {
            handelModalType("failed");
          }
        }
      } else {
        handelModalType("failed");
      }
    } catch (error) {
      handelModalType("failed");
    } finally {
      dispatch(updateAuth({ loading: false }));
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const response = await fetch(
        "https://admin.bab-media.com/public/api/payment/otp/verify",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
          body: JSON.stringify({ otp }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to verify OTP");
      }

      const result = await response.json();
      setIsModalOpen(false);
      if (result.ok) {
        setErrorMessage("OTP verified successfully");
      } else {
        throw new Error(
          result.message || "Unknown error during OTP verification"
        );
      }
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const handleResendOtp = async () => {

    try {
      const response = await fetch(
        "https://admin.bab-media.com/public/api/payment/otp/resend",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to resend OTP");
      }
      setResendVisible1(true)
      setOtpMessage(
        "An OTP has been resent to your email. It will expire within 5 minutes."
      );
      setTimeout(()=>{
        setResendVisible1(false)
      },120000)
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const handleContinue = async () => {
    setTimeout(() => {
      setResendVisible(true);
    }, 300000);
    try {
      const response = await fetch(
        "https://admin.bab-media.com/public/api/payment/otp/generate",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to send OTP");
      }

      setIsModalOpen(true);
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <CardElement onChange={onChangeCard} />

        {recaptchaError && (
          <div>
            <p className="my-4 text-xs text-red-700 font-medium">
              {recaptchaError}
            </p>
          </div>
        )}
        {errorMessage === "OTP verified successfully" && (
          <div>
            <p className="my-4 text-xs text-green-700 font-medium">
              {errorMessage}
            </p>
          </div>
        )}
        <ReCAPTCHA
          className="mt-8"
          sitekey="6Le84TsqAAAAACaoQneF1ELV6M8LtgExgrcHGdPl" // Replace with your site key
          onChange={handleRecaptcha}
        />

        {errorMessage === "OTP verified successfully" ? (
          <button
            type="button"
            onClick={handleSubmit}
            className={`block mt-8 w-full mx-auto 
            bg-secondary bg-orange-200 cursor-not-allowed"
             text-white rounded-lg px-3 py-2 font-semibold`}
          >
            PAY NOW
          </button>
        ) : (
          <button
            type="button"
            onClick={handleContinue}
            className={`block mt-8 w-full mx-auto ${
              isFormValid()
                ? "bg-secondary"
                : "bg-orange-200 cursor-not-allowed"
            } text-white rounded-lg px-3 py-2 font-semibold`}
            disabled={!isFormValid()}
          >
            CONTINUE
          </button>
        )}
      </form>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <button
              className="close-button"
              onClick={() => setIsModalOpen(false)}
            >
              &times; {/* Close button symbol */}
            </button>
            <h2 className="font-bold">OTP Verification</h2>

            
            <div className="mb-4 mt-4">
              <input
                type="number"
                value={otp}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value.length <= 6) {
                    setOtp(value);
                  }
                }}
                placeholder="Enter OTP"
                className="border border-black rounded-lg px-2 py-1 w-1/2" // Added border color
                required
                maxLength={6}
              />
               
              <p className="text-xs text-gray-600 mt-4">
                An OTP has been sent to your email. It will expire within 5
                minutes.
              </p>
              {errorMessage !== "null" && (
                <div>
                  <p className="my-4 text-xs text-red-700 font-medium">
                    {errorMessage}
                  </p>
                </div>
              )}

              <button
                disabled={resendVisible1} // Enable/disable based on your logic
                type="button"
                onClick={handleResendOtp}
                className={`mt-2 ${
                  resendVisible1 
                    ? "text-gray-400 cursor-not-allowed"
                    : "text-blue-500 underline"
                }`}
              >
                Resend OTP
              </button>

              <button
                type="button"
                onClick={() => {
                  handleVerifyOtp();
                  CloseModal();
                }}
                className={`block mt-8 w-full mx-auto bg-secondary hover:bg-orange-200 text-white rounded-lg px-3 py-2 font-semibold`}
              >
                VERIFY OTP
              </button>
            </div>
          </div>
        </div>
      )}

      <style jsx>{`
        .modal {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }
        .modal-content {
          background: white;
          padding: 20px;
          border-radius: 5px;
          text-align: center;
          position: relative; // To position close button absolutely
        }
        .close-button {
          position: absolute;
          top: 10px;
          right: 10px;
          background: none;
          border: none;
          font-size: 24px;
          cursor: pointer;
        }
      `}</style>
    </div>
  );
};

export default CheckoutForm;
